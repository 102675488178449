/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background-color: #121212;
  color: #fff;
  overflow-x: hidden;
}

.react-flow__renderer {
  height: 100%;
  background-color: #1a192b;
  color: white;
}

.vertical-timeline-element-content {
  background-color: rgb(18 24 38) !important;
  color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3) !important;
}

.vertical-timeline-element-date {
  color: #b0b0b0 !important;
}

.vertical-timeline-element-icon {
  box-shadow: none !important; /* Remove the box-shadow */
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid transparent !important;
}

.tag {
  display: inline-block;
  padding: 4px 8px;
  border: 2px solid;
  border-radius: 8px;
  color: inherit;
  font-size: 1rem;
}

.vertical-timeline-element-subtitle {
  font-size: 1rem;
  font-weight: 400;
}

.icon-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid currentColor;
}
