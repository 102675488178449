/* ErrorPage.css */
#error__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1c1c1c;
    color: #fff;
    text-align: center;
  }
  
  #error__page h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  #error__page h4 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #ff6b6b;
  }
  
  #error__page .clicker-container {
    margin-bottom: 2rem;
  }
  
  #error__page .clicker-container button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #ff6b6b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #error__page .clicker-container button:hover {
    background-color: #ff3b3b;
  }
  
  #error__page .clicker-container p {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  
  #error__page a {
    font-size: 1.5rem;
    color: #6c63ff;
    text-decoration: none;
    margin-top: 2rem;
    transition: color 0.3s ease;
  }
  
  #error__page a:hover {
    color: #483dff;
  }
  