/* Flow.css */

.react-flow__pane {
    background: transparent !important;
  }
  
  .node {
    background: #2e2e2e;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .react-flow__node input {
    background: #f6ab6c;
    border-radius: 50%;
  }
  
  .react-flow__node-default {
    background: #6c8df6;
    border-radius: 8px;
  }
  
  .react-flow__node-output {
    background: #d5a6bd;
    border-radius: 50%;
  }
  
  .edge {
    stroke-width: 3;
  }
  
  .edge-path {
    stroke: #aaa;
    stroke-dasharray: 5, 5;
  }
  